import styles from './ReadingTime.css'
import eye from '/images/icons/eye.raw.svg'
import { Icon } from '/features/buildingBlocks/Icon'
import { useTranslate } from '/machinery/I18n'

export function ReadingTime({ readingTime, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <Icon icon={eye} />
      <div>{readingTime + __`min`}</div>
    </div>
  )
}
