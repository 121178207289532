import { useTranslate } from '/machinery/I18n'
import { HeadingSm } from '/features/buildingBlocks/Heading'
import { ReadingTime } from '/features/buildingBlocks/ReadingTime'
import { ImageCropped } from '/features/buildingBlocks/Image'
import { useEnteredState } from '/machinery/useEnteredState'
import { TextSecondarySm } from '/features/buildingBlocks/Text'
import { LinkButtonGhost } from '/features/buildingBlocks/Link'

import styles from './ArticleCard.css'

export function ArticleCard({
  image,
  aspectRatio,
  title,
  introText,
  url,
  readingTime,
  layoutClassName = undefined,
}) {
  const { __ } = useTranslate()
  const { ref: hoverRef, hovered } = useEnteredState()
  return (
    <article ref={hoverRef} className={cx(styles.component, layoutClassName)} data-x='article'>
      <div className={cx(styles.content, styles.relativeToParent)}>
        <div className={cx(styles.text, styles.relativeToParent)}>
          <HeadingSm h={3}>{title}</HeadingSm>
          <TextSecondarySm>
            <p className={styles.lineClamp}>{introText}</p>
          </TextSecondarySm>
          <LinkButtonGhost href={url} dataX="link-to-article" relativeToParent>
            {__`lees-meer`}
          </LinkButtonGhost>
        </div>
      </div>
      <div className={styles.readTime}>
        <ReadingTime
          layoutClassName={styles.readingTime}
          {...{ readingTime }}
        />
      </div>
      <div className={cx(styles.image, hovered && styles.isHovered)}>
        <ImageCropped {...{ aspectRatio, image }} imgProps={{ loading: 'lazy' }} />
      </div>
    </article>
  )
}
