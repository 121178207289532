import { asRouteMap } from '@kaliber/routing'
import groq from 'groq'
import { parameterExtraction } from '@kaliber/sanity-routing/sanity'
import { toPlainText } from '@portabletext/react'
const { extract, language, slug } = parameterExtraction

export const routeMap = asRouteMap({
  root: '',
  api: {
    path: 'api',

    v1: {
      path: 'v1',

      url: {
        path: 'document/path',
        data: requestHandlers => requestHandlers.determineDocumentPath,
      },

      cookieConsent: {
        path: 'cookieConsent',
        data: requestHandlers => requestHandlers.setCookieConsent,
      }
    },
    notFound: '*'
  },
  preview: 'preview',
  app: {
    path: ':language',
    data: {
      fetch: {
        cookieConsent: requestHandlers => requestHandlers.getCookieConsent
      },
      groq: ({ params: { language } }) => ({
        settings: groq`*[_id == 'settings'][0]{..., footerLinks[]->}`,
        notFound: [
          groq`*[_type == 'notFound' && language == $language][0]`,
          { language }
        ],
      }),
    },

    home: {
      path: '',
      data: {
        groq: ({ params: { language } }) => ({
          doc: [
            groq`*[_type=='home' && language == $language][0]{
              ...,
              heroImages[]{..., asset->},
              interactieveVideos[]{
                ...,
                thumbnail{..., asset->}
              },
              linkedInCta{..., image{..., asset->}}
            }`,
            { language }
          ],
          artikelen: [
            groq`*[_type=='article' && language == $language] | order(publicationDate desc)[0..3]{
              ...,
              image{..., asset->},
              content[] {
                ...,
                _type == 'internalLink' => { ref-> }
              }
            }`,
            { language }
          ],
        }),
        extractParams: { home: extract(language) },
        derived: ({ data }) => (data.doc && {
          doc: docWithSeo(data.doc, {
            title: toPlainText(data.doc.heroTitle ?? []),
            social: {
              shareImage: firstOrUndefined(data.doc.heroImages)
            }
          }),
          dataLayer: dataLayer({
            title: 'home',
            id: data.doc._id,
            category: 'home'
          })
        })
      },
    },
    articles: {
      path: 'artikelen',
      data: {
        groq: ({ params: { language } }) => ({
          doc: [
            groq`*[_type == 'articleOverview' && language == $language][0]`,
            { language }
          ],
          articles: [
            groq`*[_type=='article' && language == $language] {..., image{..., asset->}}`,
            { language }
          ],
        }),
        extractParams: { articleOverview: extract(language) },
        derived: ({ data }) => (data.doc && {
          doc: docWithSeo(data.doc, {
            title: toPlainText(data.doc.title ?? []),
            social: {
              shareImage: firstOrUndefined(data.articles)?.image
            }
          }),
          dataLayer: dataLayer({
            title: 'artikelen',
            id: data.doc._id,
            category: 'article_overview'
          })
        })
      },
      article: {
        path: ':slug',
        data: {
          groq: ({ params: { slug, language } }) => ({
            doc: [
              groq`*[_type == 'article' && language == $language && slug.current == $slug]| order(_updatedAt desc) [0]{
                ...,
                image{..., asset->},
                content[]{
                  ...,
                  _type == 'kaliberImage' => {..., image{..., asset->}},
                  markDefs[]{
                    ...,
                    _type == 'link' => {
                      ...,
                      internalOrExternalLink[] {
                        ...,
                        _type == 'internalLink' => {..., ref->}
                      }
                    }
                  }
                }
              }`,
              { language, slug }
            ],
            otherArticles: [
              groq`*[_type == 'article' && language == $language && slug.current != $slug] {
                ...,
                image{..., asset->}
              }
              | order(publicationDate desc)[0..2]`,
              { language, slug }
            ],
            titles: [
              groq`*[_type == 'titles' && language == $language][0]`,
              { language }
            ],
          }),
          extractParams: { article: extract(language, slug) },
          derived: ({ data }) => (data.doc && {
            doc: docWithSeo(data.doc, {
              title: toPlainText(data.doc.title ?? []),
              description: data.doc.introText,
              social: {
                shareImage: data.doc.image
              }
            }),
            dataLayer: dataLayer({
              title: data.doc.slug?.current,
              id: data.doc._id,
              category: 'article'
            })
          })
        },
      },
    },
    content: {
      path: ':slug',
      data: {
        groq: ({ params: { slug, language } }) => ({
          doc: [
            groq`*[_type == 'content' && language == $language && slug.current == $slug]| order(_updatedAt desc) [0] {
              ...,
              blocks[] {
                ...,
                markDefs[]{
                  ...,
                  _type == 'link' => {
                    ...,
                    internalOrExternalLink[] {
                      ...,
                      _type == 'internalLink' => {..., ref->}
                    }
                  }
                }
              }
            }`,
            { language, slug }
          ],
        }),
        extractParams: { content: extract(language, slug) },
        derived: ({ data }) => (data.doc && {
          doc: docWithSeo(data.doc, {
            title: data.doc.title
          }),
          dataLayer: dataLayer({
            title: data.doc.slug?.current,
            id: data.doc._id,
            category: 'content_page'
          })
        })
      },
    },
    library: {
      path: 'library',
    },
    notFound: '*',
  },
  admin: 'admin',
},
{ trailingSlash: true }
)

function dataLayer(content) {
  return {
    metadata: { content }
  }
}

function docWithSeo(doc, fallback) {
  return {
    ...doc,
    seo: mergeObjectWithoutType(fallback, doc.seo)
  }
}

function firstOrUndefined(array) {
  const [first] = array
  return first
}

function mergeObjectWithoutType(target, source) {
  if (!(target instanceof Object) || !(source instanceof Object) || ('_type' in source)) return source

  return Object.entries(source).reduce(
    (result, [key, value]) => ({
      ...result,
      [key]: mergeObjectWithoutType(result[key], value)
    }),
    target
  )
}
