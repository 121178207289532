import { routeMap } from '/routeMap'

import { useLanguage } from '/machinery/I18n'
import { useMediaQuery } from '/machinery/useMediaQuery'

import  { ArticleCard }  from '/features/buildingBlocks/ArticleCard'
import { toPlainText } from '@portabletext/react'
import cloud from '/images/cloud-black.png'

import mediaStyles from '/cssGlobal/media.css'
import styles from './ArticlesOverview.css'

const { viewportMd } = mediaStyles

export function ArticlesOverview({ articles }) {
  const language = useLanguage()
  const matchesViewportMd = useMediaQuery(viewportMd)

  return (
    <section className={styles.component} data-x='article-overview'>
      <div className={styles.articles}>
        {articles.map((article, i) => (
          <ArticleCard
            key={article._id}
            layoutClassName={styles.article}
            title={toPlainText(article.title)}
            url={routeMap.app.articles.article({ language, slug: article.slug.current })} // TODO: https://kaliber.atlassian.net/browse/KNBNW-90
            introText={article.introText}
            readingTime={article.readingTime}
            image={article.image}
            aspectRatio={matchesViewportMd ? getAspectRatio(i) : 3 / 2}
          />
        ))}
      </div>
      <img src={cloud} className={styles.decoration} alt='' />
    </section>
  )
}

function getAspectRatio(i) {
  const aspectRatios = [
    1 / 1,
    2 / 3,
    16 / 9,
    16 / 9
  ]

  return aspectRatios[i % aspectRatios.length]
}
